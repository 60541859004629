<template>
<div class="">
  
</div>
</template>

<script>

export default {
  name: 'demo',
  components: {
      
  },
  data(){
    return {
     
    };
  },
  methods:{
     
  },
   created() {
     
  },
}
</script>

<style scoped>


</style>
